// Filter Projects

const getValue = value =>
  typeof value === 'string' ? value.toUpperCase() : value;

function getName(key) {
  const properties = {
    COUNTRY: 'countryName',
    SEARCH: 'search',
    SECTOR: 'projectSectorDescription',
    SECTORTITLE: 'projectSectorDescription',

  };
  return properties[key.toUpperCase()] || 'none';
}

const searchStringKeys = [
  'title',
  'projectSectorDescription',
];

const filterArray = (projects, filters) => {
  const filterKeys = Object.keys(filters);
  return projects.filter(project => {
    // validates all filter criteria
    return filterKeys.every(key => {
      // ignores an empty filter
      return filters[key].find(value => {
        if (
          key === 'SEARCH' &&
          searchStringKeys.some(stringKey =>
            project[stringKey]
              .toString()
              .toUpperCase()
              .includes(value.toUpperCase())
          )
        ) {
          return true;
        } else if (
          key === 'SECTOR' &&
          (project.projectISICCodes || []).some(isic =>
            getValue(isic).startsWith(getValue(value))
          )
        ) {
          return true;
        } else if (getValue(value) === getValue(project[getName(key)])) {
          return true;
        }
      }) !== undefined
        ? true
        : false;
    });
  });
};

const filters = {
  Country: {
    title: 'Country',
    filterKey: 'COUNTRY',
    selector: item => item && item.countryName,
    finder: (collection, value) => collection.countryID === parseInt(value),
  },
  Sector: {
    title: 'Sector',
    filterKey: 'SECTOR',
    selector: item => item && item.isicCode,
    finder: (collection, value) =>
      collection.companySectorCodeID === parseInt(value),
  },
  search: {
    title: 'Search',
    filterKey: 'SEARCH',
    selector: item => item,
    finder: (c, value) => c.sdgid === parseInt(value),
  },
};

const filterOfflineInstitutions = (
  partners,
  filterParams,
  filterMasterData,
  favoriteProjects
) => {
  const { Filters } = filterParams;

  const filterLabels = Object.keys(Filters || {})
    .map(searchKey => ({ searchKey, filter: filters[searchKey] }))
    .map(({ searchKey, filter }) => ({
      [filter.filterKey]:
        searchKey === 'TotalInvestmentCost' || searchKey === 'search'
          ? Filters[searchKey]
          : searchKey === 'Favorites'
            ? favoriteProjects
            : [
              ...(Filters[searchKey] || []).map(value =>
                (
                  (filterMasterData || {})[filter.filterKey] || []
                ).find(master => filter.finder(master, value))
              ),
            ].map(filter.selector),
    }))
    .reduce((filterElements, filter) => {
      return { ...filterElements, ...filter };
    }, {});
  partners = filterArray(partners, filterLabels);
  return partners;
};

export default filterOfflineInstitutions;
