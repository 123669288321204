import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

const ProjectsSearch = ({
  onProjectsSearch,
  searchString,
  setSearchString,
  placeholder,
}) => {

  return (
    <div className='project-search'>
      <input
        type='text'
        value={searchString}
        placeholder={placeholder}
        onChange={e =>
          setSearchString(e.target.value || '')
        }
        onKeyDown={e => {
          e.keyCode === 13
            ? (e.preventDefault(), onProjectsSearch(true))
            : '';
        }}
      />
      <IconButton
        aria-label="search"
        type="button"
        onClick={() => onProjectsSearch(true)}
      >
        <SearchIcon fontSize="small" />
      </IconButton>
    </div>
  );
};

ProjectsSearch.propTypes = {
  onProjectsSearch: PropTypes.func,
  searchString: PropTypes.string,
  setSearchString: PropTypes.func,
  placeholder: PropTypes.string,
};

export default ProjectsSearch;
