import React from 'react';
import GridContainer from '../components/Grid/GridContainer.jsx';
import GridItem from '../components/Grid/GridItem.jsx';
import { useTranslation } from 'react-i18next';
import theme from '../../config/theme';
import PropTypes from 'prop-types';

const NoProjects = (props) => {
  const noProjectsStyle = {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '300px',
    fontWeight: theme.fontWeight.body,
  };

  const { t } = useTranslation();

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} style={noProjectsStyle}>
        <p>{t(`No ${props.data} to display`)}</p>
      </GridItem>
    </GridContainer>
  );
};

NoProjects.propTypes = {
  data : PropTypes.string
};

export default NoProjects;
